import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import header from '../../images/projects/puente-header.png'
import hwireframe from '../../images/projects/puente/home-wireframe.png'
import vwireframe from '../../images/projects/puente/volunteer-wireframe.png'
import nwireframe from '../../images/projects/puente/news-wireframe.png'

// Component Imports
import Layout from "../../layout"
import ResumeCTA from "../../components/resume-cta"

const Puente = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <Layout>


      <section class="project-hero" style={{ backgroundImage: `url(${header})` }}>
      </section>


      <section class="project-intro">
        <div class="sw">
          <div class="content">
            <h1>Puente Website Re-Design</h1>
            <div class="body">
              <div class="left">
                <div class="item">
                  <h3>Company</h3>
                  <ul>
                    <li>Puente | <a href="https://www.puente-dr.org" target="_blank" rel="noreferrer">puente-dr.org</a></li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Date</h3>
                  <ul>
                    <li>April 2020 - August 2020</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Process</h3>
                  <ul>
                    <li>Agile</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Role</h3>
                  <ul>
                    <li>React Developer</li>
                    <li>UX Designer</li>
                    <li>UX Researcher</li>
                  </ul>
                </div>
                <div class="item">
                  <h3>Responsibilities</h3>
                  <ul>
                    <li>Background Research</li>
                    <li>Update Branding</li>
                    <li>Lo-Fidelity Wireframing</li>
                    <li>Hi-Fidelity Wireframing</li>
                    <li>Prototyping</li>
                    <li>Full-Stack Development</li>
                    <li>Quality Assurance Testing</li>
                  </ul>
                </div>
                <div class="item item--last">
                  <h3>Technologies Used</h3>
                  <ul>
                    <li>React.js</li>
                    <li>Gatsby.js</li>
                    <li>HTML</li>
                    <li>SCSS</li>
                    <li>GraphQL</li>
                    <li>Contentful Headless CMS</li>
                    <li>Lottie</li>
                    <li>Adobe Xd</li>
                    <li>Adobe Photoshop</li>
                    <li>Adobe Illustrator</li>
                    <li>Adobe Premiere Pro</li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div class="item">
                  <h2>Overview</h2>
                  <p>Puente is a global non-profit organization that uses data collection and analysis technology to tackle some of the biggest development challenges they face in the Dominican Republic. Puente primarily uses mobile applications to assess, map, and prioritize needs in the communities they serve. They have a robust network of volunteers that are trained to use their technology to survey their communities, identify needs, and help design solutions. They envision their technology helping to create a world where development organizations collaborate intelligently and selflessly to maximize their collective impact on the individuals in the communities they serve. </p>
                </div><div class="item">
                  <h2>Problem</h2>
                  <p>I was approached by Puente in April 2020 to revamp their existing Wordpress website, and give them something more in line with what you would expect from an emerging technology company. Their Wordpress website featured a clunky layout that greatly hindered the user experience, and they felt limited in what they could do due to the limitations of Wordpress templates and complications that come with applying custom code to said templates. They did not enjoy the process and interface they had to use on the Wordpress backend to update content. </p>
                </div>
                <div class="item">
                  <h2>Solution</h2>
                  <p>To solve the issues Puente was having with their Wordpress website, it was decided that their website would be re-designed from the ground up, an intensive and iterative process consisting of two main phases; Design and Development. The design phase would consist of an overhaul of Puente’s branding before moving onto creating lo and hi fidelity wireframes of the new website. For development, we planned to create a website using Gatsby.js and HTML code using SCSS modules to style the website. In addition, GraphQL would be used to connect the website to the Contentful headless CMS to allow for quick and easy content changes to be made without Puente team members having to dig into the code. </p>
                </div>
                <div class="item item--goals">
                  <h2>Goals</h2>
                  <ol class="list">
                    <li><strong>1.</strong> Create a brand new Puente website featuring a modern and user-friendly design based on current best practices and a revamped set of company branding guidelines. </li>
                    <li><strong>2.</strong> Showcase technology that Puente creates to aid their community in a way that is fitting of a modern technology company. </li>
                    <li><strong>3.</strong> Showcase Puente’s community projects. </li>
                    <li><strong>4.</strong> Showcase featured members of Puente’s robust volunteer network, and offer ways for new volunteers to get involved. </li>
                    <li><strong>5.</strong> Enable users to stay connected with Puente on projects, technology, and other news items that Puente wishes to advertise. </li>
                    <li><strong>6.</strong> Enable users to easily support Puente through donating from the website or purchasing merchandise. </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="project-section project-section--first">
        <div class="sw">
          <div class="content">
            <div className="body">
              <h2>Wireframing and Prototyping</h2>
              {/* <p>I began this project by creating an 11 page low fidelity wireframe of the mini site using content created by a team member and approved by the client. Per the client's request, I branded the site as an Arctic Wolf site while using RVWSS fonts. I laid out the provided content based on my experience working on previous website projects and best practices of modern web design. The product pages would all follow the same outline, so I wireframed them to be identical to feel familiar to users who visited multiple product pages, and to reduce time spent on development by ensuring code could be reused during the development process.</p> */}
              <div className="images">
                <div className="img-container img-container--three">
                  <img src={hwireframe} alt="Puente Homepage Design" />
                </div>
                <div className="img-container img-container--three">
                  <img src={vwireframe} alt="Puente Volunteer Page Design" />
                </div>
                <div className="img-container img-container--three">
                  <img src={nwireframe} alt="Puente News Page Design" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <ResumeCTA />


    </Layout>
  )
}

export default Puente